import Vue from 'vue'

export default {
  SET_TIME (state, payload) {
    state.time = payload
  },
  SET_END_TIME (state, payload) {
    state.endTime = payload
  },
  SET_SCHEDULES (state, payload) {
    state.schedules = payload
  },
  ADD_SCHEDULE_TIME (state, payload) {
    state.selectedDays[payload.contextMenuIdx].studentSchedule.push({
      name: '',
      start_date: payload.start_date,
      start_time: payload.time,
      end_time: payload.endTime
    })
    state.selectedDays[payload.contextMenuIdx].studentSchedule.sort((a, b) => {
      return new Date(`${state.selectedDays[payload.contextMenuIdx].id} ${a.start_time}`) - new Date(`${state.selectedDays[payload.contextMenuIdx].id} ${b.start_time}`)
    }) //ORDENA POR DATA
  },
  //AQUI NAO UTILIZO O RETORNO DA API, NESTE CASO FAÇO A MUDANÇA ANTES DE ENVIAR PARA A API
  UPDATE_MASSIVE_SCHEDULES (state, payload) {
    payload.schedules.map(a => {
      const idx1A = state.schedules.findIndex(o => o.id === a.id)
      const index1 = state.studentSchedules.findIndex(o => o.id === a.id)
      if (idx1A >= 0) Vue.set(state.schedules, idx1A, Object.assign({}, a, payload.changes))
      if (index1 >= 0) Vue.set(state.studentSchedules, index1, Object.assign({}, a, payload.changes))
      state.selectedDays.map(e => {
        const idx = e.studentSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        if (idx >= 0) Vue.set(e.studentSchedule, idx, Object.assign({}, a, payload.changes))
      })
    })
  },

  //ATUALIZO DE ACORDO COM O RETORNO DA API
  UPDATE_SCHEDULES (state, payload) {
    payload.map(a => {
      const idx1A = state.schedules.findIndex(o => o.id === a.id)
      const index1 = state.studentSchedules.findIndex(o => o.id === a.id)
      if (idx1A >= 0) Vue.set(state.schedules, idx1A, a)
      if (index1 >= 0) Vue.set(state.studentSchedules, index1, a)
      state.selectedDays.map(e => {
        const idx = e.studentSchedule.findIndex(o => o.id === a.id)
        if (idx >= 0) Vue.set(e.studentSchedule, idx, a)
      })
    })
  },

  SET_SELECTED_DAYS (state, payload) {
    state.selectedDays = payload.sort((a, b) => {               //ORDENA AS DATAS
      return new Date(a.date) - new Date(b.date)
    })
  },
  ADD_STUDENT_SCHEDULES (state, payload) {
    state.studentSchedules = state.studentSchedules.concat(payload)
    state.selectedDays.map(e => {
      e.studentSchedule = e.studentSchedule.concat(payload)
    })
  },

  SET_STUDENT_SCHEDULES (state, payload) {
    // PEGA OS HORÁRIOS PRÉ CADASTRADOS E INSERE NAS AGENDAS PARA APARECER MESMO QUE NÃO HAJA AGENDAMENTOS
    const scheduleTimes = payload.rootState.scheduleTime.scheduleTime.specific_days
    if (scheduleTimes) {
      scheduleTimes.map(st => {
        const [start_date, start_time, end_time] = st.split(' ')
        if (state.selectedDays.findIndex(sd => sd.id === start_date) >= 0) {
          payload.schedules.push({ start_date, start_time, end_time })
        }
      })
    }
    state.studentSchedules = payload.schedules  // precisa ser assim para manter a reatividade
  },
  SET_HOLIDAY_SCHEDULES (state, payload) {
    state.holidaySchedules = payload //precisa ser assim para manter a reatividade
  },

  // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
  FILL_SELECTED_DAYS (state, payload) {

    state.selectedDays.map(selectedDay => {

      selectedDay.studentSchedule.splice(0)    // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.holidaySchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS

      state.studentSchedules.map(schedule => {  // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.start_date.slice(0, 10)) {
          selectedDay.studentSchedule.push(schedule)
        }
      })
      state.holidaySchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.date.slice(0, 10)) {
          selectedDay.holidaySchedule.push(schedule)
        }
      })
    })

    //PREENCHE OS HORÁRIOS SEMANAIS
    const weekdays = payload ? payload.weekdays : false
    if (weekdays && weekdays.length) {
      weekdays.map(wd => {
        const [start_date, start_time, end_time] = wd.split(' ')
        const days = state.selectedDays.filter(sd => sd.weekdayPosition === parseInt(start_date))
        days.map(d => d.studentSchedule.push({ start_date, start_time: `${start_time}:00`, end_time: `${end_time}:00` }))
      })
    }
    state.selectedDays.map(selectedDay => {
      selectedDay.studentSchedule.sort((a, b) => { return new Date(`${selectedDay.id} ${a.start_time}`) - new Date(`${selectedDay.id} ${b.start_time}`) }) //ORDENA POR DATA
    })
  },

  DELETE (state, payload) {
    payload.map(e => {
      const idx = state.schedules.findIndex(o => o.id === e)
      if (idx >= 0) state.schedules.splice(idx, 1)
      const idx1 = state.studentSchedules.findIndex(o => o.id === e)
      if (idx1 >= 0) state.studentSchedules.splice(idx1, 1)

      state.selectedDays.map(sd => {
        const idx2 = sd.studentSchedule.findIndex(o => o.id === e)
        if (idx2 >= 0) sd.studentSchedule.splice(idx2, 1)
      })
    })
  }
}
